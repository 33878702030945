import "../styles/Contacts.scss"

export const Contacts = () => {
    document.title = "Nous contacter"
    return(
        <section className="contact">
            <h1>Contacts</h1>
            <form action="" method="get">
                <label htmlFor="">Sujet de votre demande</label> <br />
                <input type="text" placeholder="Quel est le sujet ?"/> <br />
                <input type="email" placeholder="Votre email"/> <br />
                <textarea cols="22" rows="5" placeholder="Votre message" className="message"></textarea>
                <input type="submit" value="Envoyer" className="submit_btn"/>
            </form>
        </section>
    )
}