import logo from './logo.svg';
import './App.scss';
import { Home } from './Pages/Home';
import { NavLink, Route, Routes } from 'react-router-dom';
import { Clips } from './Pages/Clips';
import { CourtsMetrages } from './Pages/Courts métrages';
import { Contacts } from './Pages/Contacts';
import { QuelquesFilms } from './Pages/QuelquesFilms';
import { Bonus } from './Pages/Bonus';

function App() {

  return (
    <div>
      <header>
        <nav className='navigation'>
          <NavLink to=''>Accueil</NavLink>
          <NavLink to='clips'>Quelques clips</NavLink>
          <NavLink to='courts_metrages'>Courts métrages</NavLink>
          <NavLink to='bonus'>Bonus</NavLink>
          <NavLink to='quelques_films'>Quelques films</NavLink>
          <NavLink to='contacts'>Nous contacter</NavLink>
        </nav>
      </header>
      <main>
        <Routes>
          <Route path='' element={<Home/>}/>
          <Route path='clips' element={<Clips/>}/>
          <Route path='courts_metrages' element={<CourtsMetrages/>}/>
          <Route path='bonus' element={<Bonus/>}/>
          <Route path='quelques_films' element={<QuelquesFilms/>}/>
          <Route path='contacts' element={<Contacts/>}/>
        </Routes>
      </main>
      <footer>
        <h5 style={{
          color: 'white'
        }}>Images fournies par <a href='https://www.pexels.com/fr-fr/' target='_blank'>www.pexels.com/fr-fr/</a></h5>
      </footer>
    </div>
  );
}

export default App;
