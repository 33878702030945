import "../styles/QuelquesFilms.scss"
import ASilentVoice from "../Ressources/images/a-silent-voice.jpg"
import { YoutubeVideos } from "../Utils/YoutubeVideos"

export const QuelquesFilms = () => {
    document.title = "Quelques films"
    return(
        <section>
            <h1 className="main_titre">Quelques films</h1>
            <section className="silent-voice">
                <h2 className="movie_title">A Silent voice</h2>
                <img src={ASilentVoice} alt="" className="movie_image"/>
                <p className="movie_resume">Shoya Ishida était autrefois un jeune garçon reconnu comme étant la terreur de son école. Tout va basculer lors de l'arrivée de Shoko Nishimiya dans sa classe, une jeune fille sourde. Tout le londe se prends d'affection pour Shoko, sauf Shoya qui va se mettre à la harceler, à tel point que Shoko se voit obligé de changer d'école. C'est alors que tout le monde se retourne contre Shoya.<br />5 ans plus tard, lors de son entrée au lycée, il a donc bien changé et est passé de status de chef de file à victime, ayant très mauvaise réputation, à cause du harcèlement qu'il a causé à laa jeune fille, se trouvant ainsi sans amis. Il n'a alors plus qu'une idée en tête : retrouver Shoko afin de lui demander pardon.</p>
                <YoutubeVideos id="0MelIAXhyLc" name="Bande annonce"/>
            </section>
        </section>
    )
}