import '../styles/Home.scss'

export const Home = () => {
    document.title = "Accueil"

    return(
        <section>
            <div className='title_division'>
                <h1 className='title'>Aide au harcèlement</h1>
            </div>
            <p>Bienvenue sur mon site d'aide contre le harcèlement scolaire. Vous pourrez y retrouuver différents éléments qui évoquent le sujet, notamment des clips ainsi que des courts métrages.</p>
            <section className="infos">
                <p>
                    Le harcèlement scolaire touche près d'1 personne sur 10 <br />
                    Vous êtes témoin ? Victime ? Parlez-en autour de vous. <br />
                    Le harcèlement est puni par la loi.
                </p>
            </section>
        </section>
    )
}