import "../styles/Courts métrages.scss"
import { YoutubeVideos } from "../Utils/YoutubeVideos"

export const CourtsMetrages = () => {
    document.title = "Courts métrages"
    return(
        <section>
            <h1 className="titre_cm">Quelques courts métrages</h1>
            <p className="infos_cm">Sur cette page, vous y trouverez toute une floppée de courts métrages évoquant le harcèlement scolaire. Néanmoins, il faut savoir qu'en raison du sujet qui est très délicat, certains d'entre eux finissent mal.</p>
            <section className="courts_metrages">
                <YoutubeVideos id="5Nxjwau7aEY" name="DANS NOS YEUX- Court métrage"/>
                <YoutubeVideos id="UE6ZlMc4X48" name="Le jour de trop - Court-Métrage contre le Harcèlement"/>
                <YoutubeVideos id="QcV-r8FpnyU" name="AGIS! Court-métrage contre le harcèlement"/>
                <YoutubeVideos id="vpbe_Aoihjk" name="Un jeu soi-disant marrant - Court Métrage Harcèlement Collège Périers"/>
                <YoutubeVideos id="PYLMLcVMXrQ" name="RESTER DEBOUT Harcèlement scolaire-Ciné-club du lycée des Métiers d'Aniche"/>
                <YoutubeVideos id="LZNuM_AMNQc" name="PUZZLE - COURT-MÉTRAGE 2021 (harcèlement scolaire)"/>
                <YoutubeVideos id="6FMoiGghce4" name="GRAVE ! (Harcèlement scolaire)"/>
                <YoutubeVideos id="UCLHuEYHlBA" name="Option AIS - Couleur alcool - Court Métrage contre le harcèlement scolaire"/>
                <YoutubeVideos id="NekbISTowB0" name="SAVONNETTE - Court Métrage Harcèlement Scolaire"/>
                <YoutubeVideos id="sQhBRcNxayw" name="Film sur le harcelement scolaire - Lisa"/>
                <div style={{
                    gridColumn: 'span 2 / span 2'
                }}>
                    <YoutubeVideos id="4mx8KIU0xyY" name='HARCÈLEMENT Court-métrage film complet 01700 OUF collège adolescence jugement'/>
                </div>
                <YoutubeVideos id="LNecBrnE9TA" name="Harcèlement Ado collège"/>
                <YoutubeVideos id="rcJf-tgknsY" name="Parle. - Court-métrage sur le harcèlement scolaire [ENG/SPA/DEU sub] [School bullying/Acoso escolar]"/>
                <YoutubeVideos id="lq_rIomTOoA" name="Même pas en rêve ! Lycée Ernest Ferroul - Non au Harcèlement"/>
                <YoutubeVideos id="AjCKSGUy148" name={"Court métrage \"L'indifférence tue\" prix \"Non au harcèlement\" 2021"}/>
                <YoutubeVideos id="HwORszEu3ig" name="Silence toxique. Harcèlement scolaire."/>
                <YoutubeVideos id="k5aaghD6hKA" name="Une Nouvelle Chance - Harcèlement scolaire"/>
                <YoutubeVideos id="ZiW08jC4P5s" name="PRIX COUP DE CŒUR : Non au harcèlement CM2 Leroy Rostand"/>
                <YoutubeVideos id="gX--7YGl-iE" name="briser le silence" />
                <YoutubeVideos id="Bg9FR4IrQwk" name="Et si ça s'arrêtait - Film pour la lutte contre le harcèlement scolaire"/>
                <YoutubeVideos id="JT3odVY4CmU" name="Le Cercle, court métrage sur le harcèlement | Ville de Mulhouse"/>
                <YoutubeVideos id="7Cs5ggjnSrk" name="CAUCHEMARS. (Court-métrage - @strangewoodproduction1077 )"/>
                <YoutubeVideos id="eKhJEGYC30U" name="Pourquoi t'as fait ça !? (court-métrage @strangewoodproduction1077 )"/>
                <YoutubeVideos id="WoJtIQ2B1lU" name="C'est envoyé" />
                <YoutubeVideos id="GRSxeC8F9wI" name="Et si c'était moi - prévention racket"/>
                <p>{'I\'ve seen the movie.'}</p>
            </section>
            <section className="reportages">
                <h2>Quelques reportages</h2>
            </section>
        </section>
    )
}