import { YoutubeVideos } from '../Utils/YoutubeVideos'
import '../styles/Clips.scss'

export const Clips = () => {
    document.title = "Quelques clips"
    return(
        <section>
            <div className='clips'>
                <YoutubeVideos id="Rp5U5mdARgY" name="Indochine - College Boy (Clip officiel)"/>
                <YoutubeVideos id="9JaVW9u-zrE" name="Keen'v - Petite Emilie ( clip officiel )"/>
                <YoutubeVideos id="6zUxsrJqg14" name="Maëlle - L'effet de masse (Clip Officiel)"/>
                <YoutubeVideos id="zgBEVbDzuu4" name="Soprano - Fragile (Clip officiel)"/>
            </div>
        </section>
    )
}