import { DeezerMusics } from "../Utils/DeezerMusics"
import { YoutubeVideos } from "../Utils/YoutubeVideos"

export const Bonus = () => {
    document.title = "Bonus"
    return(
        <section>
            <YoutubeVideos id="utkuLf8mE6k" name='INFLUENCEUSE - Court Métrage'/>
            <p style={{
                textAlign: "center"
            }}>J'aimerais partager avec vous ce petit court métrage qui n'a rien à voir avec le harcèlement,<br />mais il reste très intéressant car il démontre bien le danger que peuvent représenter les réseaux sociaux.</p>
            <DeezerMusics type="track" id="913858"/>
        </section>
    )
}